<template>
  <AbstractFilter
    :on-filter="filterPolls"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="poll_new"
    filter-panel-class="filter-panel-poll"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('poll.filter.title')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('poll.filter.id')"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import CoreApi from '@/api/core'
import PollFilter from '@/model/PollFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PollFilter',
  data () {
    return {
      filter: this._.cloneDeep(PollFilter),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.POLL_PERMISSIONS
    }
  },
  components: {
    AbstractFilter,
    Input
  },
  methods: {
    filterPolls () {
      this.$store.commit('poll/setPage', 1)
      this.$store.commit('poll/setFilter', this.filter)
      this.$store.dispatch('poll/fetchPolls')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(PollFilter)
      this.$store.commit('poll/setFilter', this.filter)
      this.$store.dispatch('poll/fetchPolls')
    },
    getPollCategories () {
      CoreApi().get('/pollCategory')
        .then(response => {
          Object.keys(response.data).forEach(id => {
            this.categories.push({ id: id, title: response.data[id] })
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['poll/filter']
  },
  created () {
    this.getPollCategories()
  }
}
</script>

<style lang="scss">
.filter-panel-poll {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
