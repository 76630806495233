<template>
  <section>
    <PollFilter />
    <Preloader v-if="callingAPI" />
    <PollTable
      :polls="polls"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
      />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import PollFilter from '@/components/poll/PollFilter'
import PollTable from '@/components/table/PollTable'

export default {
  name: 'PollListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    polls () {
      return this.$store.getters['poll/list']
    },
    totalCount () {
      return this.$store.getters['poll/totalCount']
    },
    page () {
      return this.$store.getters['poll/page']
    }
  },
  components: {
    PollTable,
    Preloader,
    PollFilter
  },
  methods: {
    getPolls () {
      this.$store.dispatch('poll/fetchPolls')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('poll/setPage', page)
      this.getPolls()
    }
  },
  mounted () {
    this.getPolls()
  }
}
</script>
