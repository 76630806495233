<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="polls"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :button-required-permissions="requiredPermissions"
    store-name="poll"
    store-action-reload-name="fetchPolls"
    table-css-class="poll-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
      />
      <CheckColumn
        :header="tableHeaders[2].name"
        :check="item.hidden"
      />
      <CheckColumn
        :header="tableHeaders[3].name"
        :check="item.enabledVoting"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
      <CreatedByAndAtColumn
        :title-header="tableHeaders[5].name"
        :subtitle-header="tableHeaders[5].subHeader"
        :created-at="item.modifiedAt"
        :created-by="item.modifiedBy"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import CheckColumn from '@/components/table/columns/CheckColumn'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PollTable',
  props: {
    polls: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    CheckColumn,
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('poll.list.id') },
        { name: this.$t('poll.list.title') },
        { name: this.$t('poll.list.hidden') },
        { name: this.$t('poll.list.enabled_voting') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        {
          name: this.$t('system.modified_at'),
          subHeader: this.$t('system.modified_by')
        },
        { name: this.$t('actions') }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.POLL_PERMISSIONS
    }
  }
}
</script>
<style lang="scss">
  .poll-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) auto rem(80px) rem(80px) rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) auto rem(80px) rem(80px) rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
